@import "../../styles/_variables.scss";

.buttonLink {
  height: 40px;
  border: none;
  color: $white;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15);
  line-height: 1.5;
  padding: 8px;
  font-size: $font-standard;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  & + .buttonLink {
    margin-left: 10px;
  }
  &Small {
    padding: 3px 6px;
    font-size: $font-standard;
  }
  // &Inline {
  //   //min-width: auto;
  // }
  &Notification {
    //min-width: auto;
    padding: 3px 6px;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  & svg {
    margin: -7px 0;
  }
}

.buttonInfo {
  background-color: $brand-navy;
  border-color: $brand-navy;
  box-shadow: 0 1px 0 rgba($brand-navy, 0.15);
  width: fit-content;
  min-width: 140px;
  padding: 0 10px;
  // &:hover {
  //   // box-shadow: 0 8px 15px rgba($brand-navy, 0.3);
  // }
  &:disabled {
    cursor: default;
    background-color: $btn-dark;
    border-color: $btn-dark;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15);
  }
}

.buttonInactive {
  background-color: $btn-dark;
  border-color: $btn-dark;
  box-shadow: 0 1px 0 rgba($btn-dark, 0.15);
  width: fit-content;
  color: $text-color;
  min-width: 140px;
  padding: 0 10px;
  // &:hover {
  //   // box-shadow: 0 8px 15px rgba($btn-dark, 0.3);
  // }
}

.buttonError {
  width: fit-content;
  min-width: 140px;
  padding: 0 10px;
  background-color: $brand-red;
  border-color: $brand-red;
  // &:hover {
  //   box-shadow: 0 8px 15px rgba($brand-red, 0.3);
  // }
  &:disabled {
    pointer-events: none;
    background-color: $btn-dark;
  }
}

.buttonCustom {
  width: fit-content;
  min-width: 150px;
  padding: 0 10px;
  background-color: $brand-red;
  border-color: $brand-red;
  // &:hover {
  //   box-shadow: 0 8px 15px rgba($brand-red, 0.3);
  // }
  &:disabled {
    pointer-events: none;
    background-color: lighten($brand-red, 10);
    border-color: lighten($brand-red, 10);
  }
}

.buttonInverted {
  width: fit-content;
  min-width: 140px;
  padding: 0 10px;
  background-color: $light-grey;
  border-color: $light-grey;
  color: $brand-navy;
  box-shadow: 0 1px 0 rgba($brand-navy, 0.15);
  // &:hover {
  //   box-shadow: 0 8px 15px rgba($brand-navy, 0.3);
  // }
  &:disabled {
    cursor: default;
    background-color: $btn-dark;
    border-color: $btn-dark;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15);
  }
}

.rgb {
  background: $rgb-gradiant;
}
