@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./fonts/Helvetica.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
