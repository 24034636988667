.contentLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 50vh;

  &Small {
    margin: 20px 0;
    height: 50px;
  }
  &Overlay {
    position: absolute;
    background: rgba(white, 0.5);
    z-index: 9999;
    top: 0;
    height: 100%;
    width: 100%;
  }
  &Inline {
    height: 40px;
    background: transparent;
    & svg {
      fill: white;
    }
  }
  &Button {
    height: 24px;
    background: transparent;
    & svg {
      height: 24px;
      fill: white;
    }
  }
  &Dark {
    height: 100%;
    background: transparent;
    & svg {
      fill: white;
    }
  }
}
