$app-font: "Roboto", sans-serif;
$border-standard: 1px solid #e9e9e9;
$border-double: 2px solid #e9e9e9;
$radius-standard: 4px;

$font-standard: 16px;
$font-subtitle: 18px;
$font-title: 20px;
$font-label: 36px;
$font-hero: 72px;
$bold: 600;

$placeholder-color: #aaaaaa;
$input-border: 1px solid #001a3e;
$form-background: #fff;
$text-color: #5e5f64;

$white: #fff;
$whitesmoke: #f5f5f5;
$black: #000;
$light-grey: #e9ecef;
$background-grey: #e9e9e9;
$brand-grey: #5e5f64;
$off-grey: #696969;

$brand-navy: #001a3e;
$brand-red: #8f0018;
$brand-green: #39c449;
$brand-yellow: #ebb203;

$transparent-grey: #000000a1;
$dark-grey: #252525;

$btn-purple: #7460ee;
$btn-purple-hover: #563dea;
$btn-dark: #e9e9e9;
$btn-dark-hover: #171e21;

$spacing-small: 5px;
$spacing-vertical: 10px;
$spacing-horizontal: 20px;

$height-standard: 40px;

$rgb-gradiant: linear-gradient(
    90deg,
    rgba(202, 62, 71, 1) 0%,
    rgba(13, 139, 52, 1) 50%,
    rgba(13, 139, 52, 1) 50%,
    rgba(28, 65, 116, 1) 75%,
    rgba(28, 65, 116, 1) 75%,
    rgba(0, 26, 62, 1) 98%,
    rgba(0, 26, 62, 1) 100%
);

$small-breakpoint: 576px;
$med-breakpoint: 800px;
$default-margin: 0 5px 20px 20px;

