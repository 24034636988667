@import 'minireset.css/minireset';
@import 'styles/_variables.scss';

body, html {
  overscroll-behavior-y: none;
}

html,
body,
.app {
  position: fixed;
  overflow: auto;
  height: 100vh;
  width: 100vw;
  background: $background-grey;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $small-breakpoint) {
    background-color: white;
  }

  scrollbar-width: normal;
  scrollbar-color: #001A3E rgb(230, 233, 236);
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background: rgb(230, 233, 236);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #001A3E;
  }
}

h1,
h2,
h3,
a,
div,
span,
button,
input {
  font-family: $app-font;
  outline: none;
}

* {
  font-family: $app-font !important;
}

a{
  text-decoration: unset;
}

body {
  position: fixed;
  overflow: auto;
  height: 100vh;
  width: 100vw;
  background: url('./assets/images/background.jpg') no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.splash {
  height: 100vh;
  width: 100vw;
  background: url('./assets/images/background.jpg') no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background: rgba(0, 0, 0, 0.63);
  width: 200px;
  height: 200px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
}

.link {
  font-size: 60px;
  width: fit-content;
}
